<template>
  <loading v-if="isLoading" />
  <div v-else class="enrolled-course">
    <b-row>
      <b-col md="12" class="mb-4">
        <packages :packages="packages" />
      </b-col>

      <b-col md="12" class="mb-4">
        <h2 class="title">{{ $t("g.classroom.acadmic_courses") }}</h2>
        <div
          class="mt-1 d-flex flex-wrap"
          v-if="enrolled && enrolled.length > 0"
        >
          <card v-for="(item, index) in enrolled" :key="index" :item="item" />
        </div>
        <div v-else class="no-data">
          {{ $t("there_is_no_enrolled_acadmic_courses") }}
        </div>
      </b-col>

      <b-col md="12 mt-2">
        <h2 class="title">{{ $t("g.classroom.summer_courses") }}</h2>
        <div class="mt-1 d-flex flex-wrap" v-if="summer && summer.length > 0">
          <card v-for="(item, index) in summer" :key="index" :item="item" />
        </div>
        <div v-else class="no-data mb-5">
          {{ $t("there_is_no_enrolled_summer_courses") }}
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

import Loading from "@/components/loading/loading.vue";
import Card from "@/components/classrooms/enrolledCourse/card/card.vue";
import Packages from "@/views/classrooms/enrolledCourse/packages/index.vue";

export default {
  components: {
    Card,
    BRow,
    BCol,
    Loading,
    Packages,
  },
  data() {
    return {
      enrolled: [],
      summer: [],
      packages: [],
      isError: false,
      isLoading: true,
    };
  },
  created() {
    this.getAllData();
  },
  methods: {
    async getAllData() {
      try {
        const [enrolmentsResponse, packagesResponse] = await Promise.all([
          this.$http.get("student/enrollments"),
          this.$http.get("student/my-packages"),
        ]);
        enrolmentsResponse.data.data.forEach((item) => {
          if (item.course.is_summer) {
            this.summer.push(item);
          } else {
            this.enrolled.push(item);
          }
        });
        this.packages = packagesResponse.data.data;
      } catch (error) {
        console.log(error);
        this.$helpers.handleError(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./_index.scss";
</style>
